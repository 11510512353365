import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // redirect: { name: "dashboard" },
    name: "dashboard",
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Dashboard",
      layout: "dashboard",
    },
    component: () => import("../views/Dashboard.vue"),
    beforeEach: (to, from, next) => {
      next({ name: "dashboard" });
    },
  },
  {
    path: "*",
    name: "404",
    meta: {
      title: "404",
      layout: "dashboard",
    },
    component: () => import("../views/errors/404.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Dashboard",
      layout: "dashboard",
    },
    component: () => import("../views/Dashboard.vue"),
  },

  //about
  {
    path: "/about",
    name: "about",
    meta: {
      is_auth: false,
      title: "About",
      layout: "default",
    },
    component: () => import("../views/About.vue"),
  },
  //login
  {
    path: "/login",
    name: "login",
    meta: {
      is_auth: false,
      title: "Login",
      layout: "login",
    },
    component: () => import("../views/auth/Login.vue"),
  },

  // item router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "items",
      layout: "dashboard",
    },
    path: "/items",
    name: "items",
    component: () => import("../layouts/index/ItemsIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'articles",
          layout: "dashboard",
        },
        path: "index",
        name: "items-index",
        component: () => import("../views/Items/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: "create",
        name: "items-create",
        component: () => import("../views/Items/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "items-edit",
        component: () => import("../views/Items/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Prix ​​d'articles",
          layout: "dashboard",
        },
        path: "prices/index",
        name: "items-prices-index",
        component: () => import("../views/item_prices/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Attribuer les prix de cet élément",
          layout: "dashboard",
        },
        path: "prices/create",
        name: "items-prices-create",
        component: () => import("../views/item_prices/Create.vue"),
      },
    ],
  },

  // section router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "sections",
      layout: "dashboard",
    },
    path: "/sections",
    name: "sections",
    component: () => import("../layouts/index/ItemsIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "sections",
          layout: "dashboard",
        },
        path: "index",
        name: "sections-index",
        component: () => import("../views/sections/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: "create",
        name: "sections-create",
        component: () => import("../views/sections/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "sections-edit",
        component: () => import("../views/sections/Edit.vue"),
      },
    ],
  },

  //customer types  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "customers",
      layout: "dashboard",
    },
    path: "/customers",
    name: "customers",
    component: () => import("../layouts/index/CustomersIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste de clients",
          layout: "dashboard",
        },
        path: "index",
        name: "customers-index",
        component: () => import("../views/customers/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un compte clientèle",
          layout: "dashboard",
        },
        path: "create",
        name: "customers-create",
        component: () => import("../views/customers/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le compte clientèle",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "customers-edit",
        component: () => import("../views/customers/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Nature des comptes clients",
          layout: "dashboard",
        },
        path: "types/index",
        name: "customers-types-index",
        component: () => import("../views/customer_types/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un type de compte clientèle",
          layout: "dashboard",
        },
        path: "types/create",
        name: "customers-types-create",
        component: () => import("../views/customer_types/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "customer-types",
          layout: "dashboard",
        },
        path: "sellers/index",
        name: "customers-sellers-index",
        component: () => import("../views/customers/Sellers.vue"),
      },
    ],
  },

  //user roles router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "user_roles",
      layout: "dashboard",
    },
    path: "/user",
    name: "users",
    component: () => import("../layouts/index/UsersIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Roles",
          layout: "dashboard",
        },
        path: "roles/index",
        name: "users-roles-index",
        component: () => import("../views/user_roles/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un role",
          layout: "dashboard",
        },
        path: "roles/create",
        name: "users-roles-create",
        component: () => import("../views/user_roles/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le role",
          layout: "dashboard",
        },
        path: ":reference/roles/create",
        name: "users-roles-edit",
        component: () => import("../views/user_roles/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Utilisateurs d'application",
          layout: "dashboard",
        },
        path: "index",
        name: "users-index",
        component: () => import("../views/users/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un nouvel utilisateur",
          layout: "dashboard",
        },
        path: "create",
        name: "users-create",
        component: () => import("../views/users/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "users-edit",
        component: () => import("../views/users/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Authentications d'utilisateurs d'application",
          layout: "dashboard",
        },
        path: "authentications",
        name: "authentications-index",
        component: () => import("../views/users/Authentications.vue"),
      },
    ],
  },

  //society router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "society",
      layout: "dashboard",
    },
    path: "/societies",
    name: "societies",
    component: () => import("../layouts/index/SocietyIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "societies",
          layout: "dashboard",
        },
        path: "index",
        name: "societies-index",
        component: () => import("../views/societies/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer les informations de votre société",
          layout: "dashboard",
        },
        path: "create",
        name: "societies-create",
        component: () => import("../views/societies/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Employees",
          layout: "dashboard",
        },
        path: "employees/index",
        name: "employees-index",
        component: () => import("../views/employees/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Ajouter un employé",
          layout: "dashboard",
        },
        path: "employees/create",
        name: "employees-create",
        component: () => import("../views/employees/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Chambres froides",
          layout: "dashboard",
        },
        path: "coldrooms/index",
        name: "cold-rooms-index",
        component: () => import("../views/cold_rooms/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Chambres froides",
          layout: "dashboard",
        },
        path: "coldrooms/create",
        name: "cold-rooms-create",
        component: () => import("../views/cold_rooms/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Températures de chambres froide",
          layout: "dashboard",
        },
        path: "coldrooms/:reference/temperatures",
        name: "cold-rooms-temperatures",
        component: () => import("../views/cold_rooms/Temperatures.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Dépôts",
          layout: "dashboard",
        },
        path: "deposits/index",
        name: "deposits-index",
        component: () => import("../views/deposits/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un dépôts",
          layout: "dashboard",
        },
        path: "deposits/create",
        name: "deposits-create",
        component: () => import("../views/deposits/Create.vue"),
      },
    ],
  },

  //stocks router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "État de stock",
      layout: "dashboard",
    },
    path: "/stocks",
    name: "stocks",
    component: () => import("../layouts/index/StockIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "État de stock",
          layout: "dashboard",
        },
        path: "index",
        name: "stocks-index",
        component: () => import("../views/stocks/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "En rupture de stock",
          layout: "dashboard",
        },
        path: "out/index",
        name: "stocks-out-index",
        component: () => import("../views/stocks/OutStock.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Transférer la quantité entre les stocks",
          layout: "dashboard",
        },
        path: "transfer/quantity",
        name: "stocks-transfer-quantity",
        component: () => import("../views/stocks/transferQuantity.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Rajouter une quantité de ce produit en stock",
          layout: "dashboard",
        },
        path: "item/:itemReference/create",
        name: "stocks-create",
        component: () => import("../views/stocks/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier l'etat de stock",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "stocks-edit",
        component: () => import("../views/stocks/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Supprimer l'etat de stock",
          layout: "dashboard",
        },
        path: ":reference/delete",
        name: "stocks-delete",
        component: () => import("../views/stocks/Delete.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Statistiques d'inventaire",
          layout: "dashboard",
        },
        path: "statistics/index",
        name: "stocks-statistics-index",
        component: () => import("../views/stocks/Statistics.vue"),
      },
    ],
  },

  // sales router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "sales",
      layout: "dashboard",
    },
    path: "/sales",
    name: "sales",
    component: () => import("../layouts/index/SaleIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste de ventes",
          layout: "dashboard",
        },
        path: "index",
        name: "sales-index",
        component: () => import("../views/sales/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un vente",
          layout: "dashboard",
        },
        path: "create",
        name: "sales-create",
        component: () => import("../views/sales/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier la vente",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "sales-edit",
        component: () => import("../views/sales/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "L'ajout des articles à un vente",
          layout: "dashboard",
        },
        path: "items/create",
        name: "sales-items-create",
        component: () => import("../views/sales/items/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier les articles d'un vente",
          layout: "dashboard",
        },
        path: ":reference/items/edit",
        name: "sales-items-edit",
        component: () => import("../views/sales/items/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Valider la vente",
          layout: "dashboard",
        },
        path: ":reference/validated",
        name: "sales-validated",
        component: () => import("../views/sales/Validated.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails sur la facturation.",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "sales-details",
        component: () => import("../views/sales/Details.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Ventes (factures) remarquées",
          layout: "dashboard",
        },
        path: "noticed/index",
        name: "sales-noticed-index",
        component: () => import("../views/sales/Noticed.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Ventes à valider",
          layout: "dashboard",
        },
        path: "tovalidate/index",
        name: "sales-tovalidate-index",
        component: () => import("../views/sales/ToValidate.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Rapport client",
          layout: "dashboard",
        },
        path: "customer/report",
        name: "sales-customer-report",
        component: () => import("../views/sales/CustomerReport.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Statistiques",
          layout: "dashboard",
        },
        path: "statistics/report",
        name: "sales-statistics-report",
        component: () => import("../views/sales/Statistics.vue"),
      },
    ],
  },


  // invoice router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "invoices",
      layout: "dashboard",
    },
    path: "/invoices",
    name: "invoices",
    component: () => import("../layouts/index/InvoiceIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "invoices",
          layout: "dashboard",
        },
        path: "index",
        name: "invoices-index",
        component: () => import("../views/invoices/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: "create",
        name: "invoices-create",
        component: () => import("../views/invoices/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "invoices-edit",
        component: () => import("../views/invoices/Edit.vue"),
      },
    ],
  },

  // payments router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Paiements",
      layout: "dashboard",
    },
    path: "/payments",
    name: "payments",
    component: () => import("../layouts/index/PaymentIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste des paiements des clients",
          layout: "dashboard",
        },
        path: "customers/index",
        name: "payments-index",
        component: () => import("../views/payments/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un nouveau paiement pour le client",
          layout: "dashboard",
        },
        path: "customers/create",
        name: "payments-create",
        component: () => import("../views/payments/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le paiment",
          layout: "dashboard",
        },
        path: "customers/:reference/edit",
        name: "payments-edit",
        component: () => import("../views/payments/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails d'un paiement",
          layout: "dashboard",
        },
        path: "customers/:reference/details",
        name: "payments-details",
        component: () => import("../views/payments/Details.vue"),
      },

      // suppliers
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste des paiements des clients",
          layout: "dashboard",
        },
        path: "suppliers/index",
        name: "payments-suppliers-index",
        component: () => import("../views/payments/suppliers/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un nouveau paiement pour le client",
          layout: "dashboard",
        },
        path: "suppliers/create",
        name: "payments-suppliers-create",
        component: () => import("../views/payments/suppliers/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le paiment",
          layout: "dashboard",
        },
        path: "suppliers/:reference/edit",
        name: "payments-suppliers-edit",
        component: () => import("../views/payments/suppliers/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails d'un paiement",
          layout: "dashboard",
        },
        path: "suppliers/:reference/details",
        name: "payments-suppliers-details",
        component: () => import("../views/payments/suppliers/Details.vue"),
      },
    ],
  },

  // discounts router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Remises",
      layout: "dashboard",
    },
    path: "/discounts",
    name: "discounts",
    component: () => import("../layouts/index/DiscountIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste des remises des clients",
          layout: "dashboard",
        },
        path: "customers/index",
        name: "discounts-index",
        component: () => import("../views/discounts/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un nouveau remise pour le client",
          layout: "dashboard",
        },
        path: "customers/create",
        name: "discounts-create",
        component: () => import("../views/discounts/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier la remise",
          layout: "dashboard",
        },
        path: "customers/:reference/edit",
        name: "discounts-edit",
        component: () => import("../views/discounts/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails d'un paiement",
          layout: "dashboard",
        },
        path: "customers/:reference/details",
        name: "discounts-details",
        component: () => import("../views/discounts/Details.vue"),
      },
    ],
  },

  // credits router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Credit",
      layout: "dashboard",
    },
    path: "/credits",
    name: "credits",
    component: () => import("../layouts/index/CreditIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Crédits du clients",
          layout: "dashboard",
        },
        path: "index",
        name: "credits-index",
        component: () => import("../views/credits/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Crédits du fournisseurs",
          layout: "dashboard",
        },
        path: "suppliers/index",
        name: "credits-suppliers-index",
        component: () => import("../views/credits/suppliers/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Soldes créditeur du clients",
          layout: "dashboard",
        },
        path: "balances/index",
        name: "credits-balances",
        component: () => import("../views/credits/Balance.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails soldes créditeur du clients",
          layout: "dashboard",
        },
        path: "balances/:customer_reference/all",
        name: "credits-balances-all",
        component: () => import("../views/credits/BalanceDetails.vue"),
      },
    ],
  },

  // checks router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Paiements",
      layout: "dashboard",
    },
    path: "/checks",
    name: "checks",
    component: () => import("../layouts/index/CheckIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Chèques clients",
          layout: "dashboard",
        },
        path: "customers/index",
        name: "checks-customers-index",
        component: () => import("../views/checks/customers/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un chèque de clients",
          layout: "dashboard",
        },
        path: "customers/create",
        name: "checks-customers-create",
        component: () => import("../views/checks/customers/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Chèques clients en attente",
          layout: "dashboard",
        },
        path: "customers/pending/index",
        name: "checks-customers-pending",
        component: () => import("../views/checks/customers/Pending.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Chèques clients payes",
          layout: "dashboard",
        },
        path: "customers/paid/index",
        name: "checks-customers-paid",
        component: () => import("../views/checks/customers/Paid.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Chèques clients impayes",
          layout: "dashboard",
        },
        path: "customers/unpaid/index",
        name: "checks-customers-unpaid",
        component: () => import("../views/checks/customers/Unpaid.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Chèques clients remarquees",
          layout: "dashboard",
        },
        path: "customers/noticed/index",
        name: "checks-customers-noticed",
        component: () => import("../views/checks/customers/Noticed.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Dètails chèque client",
          layout: "dashboard",
        },
        path: "customers/:reference/details",
        name: "checks-customers-details",
        component: () => import("../views/checks/customers/Details.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Dètails chèque fournisseur",
          layout: "dashboard",
        },
        path: "suppliers/:reference/details",
        name: "checks-suppliers-details",
        component: () => import("../views/checks/suppliers/Details.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Dètails chèque client",
          layout: "dashboard",
        },
        path: "customers/:reference/edit",
        name: "checks-customers-edit",
        component: () => import("../views/checks/customers/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Chèques fournisseur",
          layout: "dashboard",
        },
        path: "suppliers/index",
        name: "checks-suppliers-index",
        component: () => import("../views/checks/suppliers/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Chèques fournisseur",
          layout: "dashboard",
        },
        path: "suppliers/create",
        name: "checks-suppliers-create",
        component: () => import("../views/checks/suppliers/Create.vue"),
      },
    ],
  },

  // settings router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "settings",
      layout: "dashboard",
    },
    path: "/settings",
    name: "settings",
    component: () => import("../layouts/index/SettingIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "settings **",
          layout: "dashboard",
        },
        path: "index",
        name: "settings-index",
        component: () => import("../views/settings/Listing.vue"),
      },
    ],
  },

  //  suppliers  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "suppliers",
      layout: "dashboard",
    },
    path: "/suppliers",
    name: "suppliers",
    component: () => import("../layouts/index/SupplierIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Fournisseurs",
          layout: "dashboard",
        },
        path: "index",
        name: "suppliers-index",
        component: () => import("../views/suppliers/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Ajouter un fournisseur",
          layout: "dashboard",
        },
        path: "create",
        name: "suppliers-create",
        component: () => import("../views/suppliers/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifer le fournisseur",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "suppliers-edit",
        component: () => import("../views/suppliers/Edit.vue"),
      },
    ],
  },

  //  purchases  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "purchases",
      layout: "dashboard",
    },
    path: "/purchases",
    name: "purchases",
    component: () => import("../layouts/index/PurchaseIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Les achats",
          layout: "dashboard",
        },
        path: "index",
        name: "purchases-index",
        component: () => import("../views/purchases/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails sur l'achat",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "purchases-details",
        component: () => import("../views/purchases/Details.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Effectué et enregistre l'achat",
          layout: "dashboard",
        },
        path: "create",
        name: "purchases-create",
        component: () => import("../views/purchases/Create.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier l'achat",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "purchases-edit",
        component: () => import("../views/purchases/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "L'ajout des articles à un achat",
          layout: "dashboard",
        },
        path: "items/create",
        name: "purchases-items-create",
        component: () => import("../views/purchases/items/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier les articles d'un achat",
          layout: "dashboard",
        },
        path: ":reference/items/edit",
        name: "purchases-items-edit",
        component: () => import("../views/purchases/items/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Achats remarquées",
          layout: "dashboard",
        },
        path: "noticed/index",
        name: "purchases-noticed-index",
        component: () => import("../views/purchases/Noticed.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Achats à valider",
          layout: "dashboard",
        },
        path: "tovalidate/index",
        name: "purchases-tovalidate-index",
        component: () => import("../views/purchases/ToValidate.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Rapport fournisseur",
          layout: "dashboard",
        },
        path: "supplier/report",
        name: "purchases-supplier-report",
        component: () => import("../views/purchases/SupplierReport.vue"),
      },
    ],
  },

  //  expenses  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "expenses",
      layout: "dashboard",
    },
    path: "/expenses",
    name: "expenses",
    component: () => import("../layouts/index/ExpenseIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Dépenses de société",
          layout: "dashboard",
        },
        path: "index",
        name: "expenses-index",
        component: () => import("../views/expenses/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Effectué et enregistre l'achat",
          layout: "dashboard",
        },
        path: "create",
        name: "expenses-create",
        component: () => import("../views/expenses/Create.vue"),
      },
    ],
  },

  //  orders  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "orders",
      layout: "dashboard",
    },
    path: "/orders",
    name: "orders",
    component: () => import("../layouts/index/OrderIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Commandes clients",
          layout: "dashboard",
        },
        path: "index",
        name: "orders-index",
        component: () => import("../views/orders/customers/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Commandes du fournisseurs",
          layout: "dashboard",
        },
        path: "suppliers/index",
        name: "orders-suppliers-index",
        component: () => import("../views/orders/suppliers/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Ajouter les articles d'une commande à envoyer au fournisseur",
          layout: "dashboard",
        },
        path: "suppliers/items/create",
        name: "orders-suppliers-items-create",
        component: () => import("../views/orders/suppliers/items/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer la commande à envoyer au fournisseur",
          layout: "dashboard",
        },
        path: "suppliers/create",
        name: "orders-suppliers-create",
        component: () => import("../views/orders/suppliers/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Details commande de fournisseurs",
          layout: "dashboard",
        },
        path: "suppliers/:reference/details",
        name: "orders-suppliers-details",
        component: () => import("../views/orders/suppliers/Details.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Details commande de clients",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "orders-details",
        component: () => import("../views/orders/customers/Details.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Effectué et enregistre la commande client",
          layout: "dashboard",
        },
        path: "create",
        name: "orders-create",
        component: () => import("../views/orders/customers/Create.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "L'ajout des articles d'un commande client",
          layout: "dashboard",
        },
        path: "items/create",
        name: "orders-items-create",
        component: () => import("../views/orders/customers/items/Create.vue"),
      },
    ],
  },

  //  quotations  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Devis",
      layout: "dashboard",
    },
    path: "/quotations",
    name: "quotations",
    component: () => import("../layouts/index/QuotationIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Devis",
          layout: "dashboard",
        },
        path: "index",
        name: "quotations-index",
        component: () => import("../views/quotations/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Details Devis",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "quotations-details",
        component: () => import("../views/quotations/Details.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Effectué et enregistre le devis",
          layout: "dashboard",
        },
        path: "create",
        name: "quotations-create",
        component: () => import("../views/quotations/Create.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "L'ajout des articles d'un devis",
          layout: "dashboard",
        },
        path: "items/create",
        name: "quotations-items-create",
        component: () => import("../views/quotations/items/Create.vue"),
      },
    ],
  },

  //  productions  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "productions",
      layout: "dashboard",
    },
    path: "/productions",
    name: "productions",
    component: () => import("../layouts/index/ProductionIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Productions",
          layout: "dashboard",
        },
        path: "index",
        name: "productions-index",
        component: () => import("../views/productions/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Effectué et enregistre la commande",
          layout: "dashboard",
        },
        path: "create",
        name: "productions-create",
        component: () => import("../views/productions/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Saisir les articles pour réaliser une production",
          layout: "dashboard",
        },
        path: "items/create",
        name: "productions-items-create",
        component: () => import("../views/productions/items/Create.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails sur la facturation.",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "productions-details",
        component: () => import("../views/productions/Details.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "purchases **",
          layout: "dashboard",
        },
        path: "slaughter/index",
        name: "slaughters-index",
        component: () => import("../views/slaughters/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Effectué et enregistre l'achat",
          layout: "dashboard",
        },
        path: "slaughter/create",
        name: "slaughters-create",
        component: () => import("../views/slaughters/Create.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "L'ajout des articles à un achat",
          layout: "dashboard",
        },
        path: "slaughter/items/create",
        name: "slaughters-items-create",
        component: () => import("../views/slaughters/items/Create.vue"),
      },
    ],
  },

  //  raw-materials  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "raw-materials",
      layout: "dashboard",
    },
    path: "/raw-materials",
    name: "raw-materials",
    component: () => import("../layouts/index/RawMaterialIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Matière Première",
          layout: "dashboard",
        },
        path: "index",
        name: "raw-materials-index",
        component: () => import("../views/raw-materials/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Effectué et enregistre un commande de matières premières",
          layout: "dashboard",
        },
        path: "create",
        name: "raw-materials-create",
        component: () => import("../views/raw-materials/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails d'une commande de matières premières",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "raw-materials-details",
        component: () => import("../views/raw-materials/Details.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "L'ajout des articles à un achat",
          layout: "dashboard",
        },
        path: "items/create",
        name: "raw-materials-items-create",
        component: () => import("../views/raw-materials/items/Create.vue"),
      },
    ],
  },

  //  backups  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "backups",
      layout: "dashboard",
    },
    path: "/backups",
    name: "backups",
    component: () => import("../layouts/index/BackupIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Backup",
          layout: "dashboard",
        },
        path: "index",
        name: "backups-index",
        component: () => import("../views/backups/Listing.vue"),
      },
    ],
  },

  //  vehicles  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "vehicles",
      layout: "dashboard",
    },
    path: "/vehicles",
    name: "vehicles",
    component: () => import("../layouts/index/VehicleIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "vehicles **",
          layout: "dashboard",
        },
        path: "index",
        name: "vehicles-index",
        component: () => import("../views/vehicles/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Effectué et enregistre la commande",
          layout: "dashboard",
        },
        path: "create",
        name: "vehicles-create",
        component: () => import("../views/vehicles/Create.vue"),
      },
    ],
  },

  //  statistics  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Statistiques",
      layout: "dashboard",
    },
    path: "/statistics",
    name: "statistics",
    component: () => import("../layouts/index/StatisticIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Statistiques",
          layout: "dashboard",
        },
        path: "index",
        name: "statistics-index",
        component: () => import("../views/statistics/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Fiche journalière",
          layout: "dashboard",
        },
        path: "daily/sheet",
        name: "statistics-daily-sheet",
        component: () => import("../views/statistics/DailySheet.vue"),
      },
    ],
  },

  // storekeeper unauthorized
  {
    path: "/storekeepe/unauthorized",
    name: "storekeepe-unauthorized",
    meta: {
      is_auth: true,
      is_storekeeper: true,
      title: "Pas autorisé",
      layout: "storekeeper",
    },
    component: () => import("../views/Unauthorized.vue"),
  },

  // storekeeper dashboard
  {
    path: "/storekeeper/dashboard",
    name: "storekeeper-dashboard",
    meta: {
      is_auth: true,
      is_storekeeper: true,
      title: "Dashboard",
      layout: "storekeeper",
    },
    component: () => import("../views/Dashboard.vue"),
  },

  // storekeeper sales routes
  {
    meta: {
      is_auth: true,
      is_storekeeper: true,
      title: "sales",
      layout: "storekeeper",
    },
    path: "/storekeeper/sales",
    name: "storekeeper-sales",
    component: () => import("../layouts/index/storekeepers/SaleIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Liste de ventes",
          layout: "storekeeper",
        },
        path: "index",
        name: "storekeeper-sales-index",
        component: () => import("../views/sales/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Créer un vente",
          layout: "storekeeper",
        },
        path: "create",
        name: "storekeeper-sales-create",
        component: () => import("../views/sales/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "L'ajout des articles à un vente",
          layout: "storekeeper",
        },
        path: "items/create",
        name: "storekeeper-sales-items-create",
        component: () => import("../views/sales/items/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Détails sur la facturation.",
          layout: "storekeeper",
        },
        path: ":reference/details",
        name: "storekeeper-sales-details",
        component: () => import("../views/sales/Details.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Ventes (factures) remarquées",
          layout: "storekeeper",
        },
        path: "noticed/index",
        name: "storekeeper-sales-noticed-index",
        component: () => import("../views/sales/Noticed.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Ventes à valider",
          layout: "storekeeper",
        },
        path: "tovalidate/index",
        name: "storekeeper-sales-tovalidate-index",
        component: () => import("../views/sales/ToValidate.vue"),
      },
    ],
  },

  // storekeeper stocks router
  {
    meta: {
      is_auth: true,
      is_storekeeper: true,
      title: "État de stock",
      layout: "storekeeper",
    },
    path: "/storekeeper/stocks",
    name: "storekeeper-stocks",
    component: () => import("../layouts/index/storekeepers/StockIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "État de stock",
          layout: "storekeeper",
        },
        path: "index",
        name: "storekeeper-stocks-index",
        component: () => import("../views/stocks/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "En rupture de stock",
          layout: "storekeeper",
        },
        path: "out/index",
        name: "storekeeper-stocks-out-index",
        component: () => import("../views/stocks/OutStock.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Statistiques d'inventaire",
          layout: "storekeeper",
        },
        path: "statistics/index",
        name: "storekeeper-stocks-statistics-index",
        component: () => import("../views/stocks/Statistics.vue"),
      },
    ],
  },

  // storekeeper orders  router
  {
    meta: {
      is_auth: true,
      is_storekeeper: true,
      title: "storekeeper-rorders",
      layout: "storekeeper",
    },
    path: "/storekeeper/orders",
    name: "storekeeper-orders",
    component: () => import("../layouts/index/storekeepers/OrderIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Commandes clients",
          layout: "storekeeper",
        },
        path: "index",
        name: "storekeeper-orders-index",
        component: () => import("../views/orders/customers/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Commandes du fournisseurs",
          layout: "storekeeper",
        },
        path: "suppliers/index",
        name: "storekeeper-orders-suppliers-index",
        component: () => import("../views/orders/suppliers/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Ajouter les articles d'une commande à envoyer au fournisseur",
          layout: "storekeeper",
        },
        path: "suppliers/items/create",
        name: "storekeeper-orders-suppliers-items-create",
        component: () => import("../views/orders/suppliers/items/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Créer la commande à envoyer au fournisseur",
          layout: "storekeeper",
        },
        path: "suppliers/create",
        name: "storekeeper-orders-suppliers-create",
        component: () => import("../views/orders/suppliers/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Details commande de fournisseurs",
          layout: "storekeeper",
        },
        path: "suppliers/:reference/details",
        name: "orders-suppliers-details",
        component: () => import("../views/orders/suppliers/Details.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Details commande de clients",
          layout: "storekeeper",
        },
        path: ":reference/details",
        name: "storekeeper-orders-details",
        component: () => import("../views/orders/customers/Details.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "Effectué et enregistre la commande client",
          layout: "storekeeper",
        },
        path: "create",
        name: "storekeeper-orders-create",
        component: () => import("../views/orders/customers/Create.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_storekeeper: true,
          title: "L'ajout des articles d'un commande client",
          layout: "storekeeper",
        },
        path: "items/create",
        name: "storekeeper-orders-items-create",
        component: () => import("../views/orders/customers/items/Create.vue"),
      },
    ],
  },

  // cashier unauthorized
  {
    path: "/cashier/unauthorized",
    name: "cashier-unauthorized",
    meta: {
      is_auth: true,
      is_cashier: true,
      title: "Pas autorisé",
      layout: "cashier",
    },
    component: () => import("../views/Unauthorized.vue"),
  },
  // cashier dashboard
  {
    path: "/cashier/dashboard",
    name: "cashier-dashboard",
    meta: {
      is_auth: true,
      is_cashier: true,
      title: "Dashboard",
      layout: "cashier",
    },
    component: () => import("../views/Dashboard.vue"),
  },

  // cashier payments router
  {
    meta: {
      is_auth: true,
      is_cashier: true,
      title: "Paiements",
      layout: "cashier",
    },
    path: "/cashier/payments",
    name: "cashier-payments",
    component: () => import("../layouts/index/cashiers/PaymentIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_cashier: true,
          title: "Paiments ",
          layout: "cashier",
        },
        path: "index",
        name: "cashier-payments-index",
        component: () => import("../views/payments/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_cashier: true,
          title: "Paiments ",
          layout: "cashier",
        },
        path: "create",
        name: "cashier-payments-create",
        component: () => import("../views/payments/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_cashier: true,
          title: "Details de paiments",
          layout: "cashier",
        },
        path: ":reference/details",
        name: "cashier-payments-details",
        component: () => import("../views/payments/Details.vue"),
      },
    ],
  },

  // viewer dashboard
  {
    path: "/viewer/dashboard",
    name: "viewer-dashboard",
    meta: {
      is_auth: true,
      is_viewer: true,
      title: "Dashboard",
      layout: "viewer",
    },
    component: () => import("../views/Dashboard.vue"),
  },

  // ecommerce admin routes
  {
    path: "/oujdafood/dashboard",
    name: "oujdafood-dashboard",
    meta: {
      is_auth: true,
      is_storekeeper: true,
      title: "Dashboard",
      layout: "oujda-food-dashboard",
    },
    component: () => import("../views/Dashboard.vue"),
  },
  // oujda-food-orders router
  {
    meta: {
      is_auth: true,
      is_ecommerce_admin: true,
      title: "oujda-food-orders",
      layout: "oujda-food-dashboard",
    },
    path: "/oujdafood/orders",
    name: "oujda-food-orders",
    component: () => import("../layouts/index/SaleIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_ecommerce_admin: true,
          title: "OujdaFood Commandes ",
          layout: "oujda-food-dashboard",
        },
        path: "index",
        name: "oujda-food-orders-index",
        component: () => import("../views/oujda_food_orders/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_ecommerce_admin: true,
          title: "oujda-food-orders **",
          layout: "oujda-food-dashboard",
        },
        path: "create",
        name: "oujda-food-orders-create",
        component: () => import("../views/oujda_food_orders/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_ecommerce_admin: true,
          title: "L'ajout des articles à un vente",
          layout: "oujda-food-dashboard",
        },
        path: "items/create",
        name: "oujda-food-orders-items-create",
        component: () => import("../views/oujda_food_orders/items/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_ecommerce_admin: true,
          title: "Détails sur la facturation.",
          layout: "oujda-food-dashboard",
        },
        path: ":reference/details",
        name: "oujda-food-orders-details",
        component: () => import("../views/oujda_food_orders/Details.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_ecommerce_admin: true,
          title: "oujda-food-orders **",
          layout: "oujda-food-dashboard",
        },
        path: "noticed/index",
        name: "oujda-food-orders-noticed-index",
        component: () => import("../views/oujda_food_orders/Noticed.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_ecommerce_admin: true,
          title: "oujda-food-orders **",
          layout: "oujda-food-dashboard",
        },
        path: "tovalidate/index",
        name: "oujda-food-orders-tovalidate-index",
        component: () => import("../views/oujda_food_orders/ToValidate.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function guard(to, from, next, authData, userRole) {
  // console.log(authData);
  if (to.meta && to.meta.is_auth == true) {
    if (authData != null && authData.reference != undefined) {
      if (to.meta.is_admin == true) {
        if (userRole != null) {
          if (userRole == "Admin") {
            return next();
          } else if (userRole == "Magasinier") {
            return next({ name: "storekeepe-unauthorized" });
          } else if (userRole == "Caissier") {
            return next({ name: "cashier-unauthorized" });
          }
        }
      } else {
        if (userRole != null) {
          if (to.meta.is_storekeeper == true) {
            if (userRole == "Admin") {
              return next();
            } else if (userRole == "Magasinier") {
              return next();
            } else if (userRole == "Caissier") {
              return next({ name: "cashier-unauthorized" });
            }
          } else if (to.meta.is_cashier == true) {
            if (userRole == "Admin") {
              return next();
            } else if (userRole == "Caissier") {
              return next();
            } else if (userRole == "Magasinier") {
              return next({ name: "storekeepe-unauthorized" });
            }
          } else if (to.meta.is_viewer == true) {
            if (userRole == "Admin") {
              return next();
            } else if (userRole == "Observateur") {
              return next();
            } else if (userRole == "Caissier") {
              // return next();
            } else if (userRole == "Magasinier") {
              // return next({ name: "storekeepe-unauthorized" });
            }
          } else if (to.meta.is_ecommerce_admin == true) {
            if (userRole == "Admin") {
              return next();
            } else if (userRole == "Ecommerce Admin") {
              return next();
            } else if (userRole == "Caissier") {
              // return next();
            } else if (userRole == "Magasinier") {
              // return next({ name: "storekeepe-unauthorized" });
            }
          }
        }
      }
    }
  }
  if (to.name == "login") {
    console.log("route login");
    return next();
  }
  next();
}

router.beforeEach((to, from, next) => {
  let authData = store.getters["auth/getAuthData"];
  let userRole = store.getters["auth/getUserRole"];
  return guard(to, from, next, authData, userRole);
});

export default router;
