import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "purchases";
// initial state
const state = () => ({
  all: [],
  purchases: [],
  items: [],
  purchase: {},
  report: [],
  errors: [],
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getErrors: (state) => state.errors,
  getItems: (state) => {
    return state.items;
  },
  getPurchase: (state) => {
    return state.purchase;
  },
  getReport: (state) => state.report,

  getPurchases: (state) => {
    return state.purchases;
  },
  totalPriceTTCItems: (state) => {
    return state.items.reduce(
      (acc, val) => acc + parseFloat(val.quantity) * parseFloat(val.priceTTC),
      0
    );
  },

  totalQuantityItems: (state) => {
    return state.items.reduce((acc, val) => acc + parseFloat(val.quantity), 0);
  },
  numberItems: (state) => {
    return state.items.length;
  },

  getReportTotalQuantity: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.totalQuantity), 0),

  getReportTotalPriceTTC: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.totalPriceTTC), 0),

  getReportTotalPaid: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.amountPaid), 0),

  getReportTotalUnpaid: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.amountUnpaid), 0),
  // /

  getPurchasesTotalQuantity: (state) =>
    state.purchases.reduce(
      (acc, val) => acc + parseFloat(val.totalQuantity),
      0
    ),
  getPurchasesTotalPriceTTC: (state) =>
    state.purchases.reduce(
      (acc, val) => acc + parseFloat(val.totalPriceTTC),
      0
    ),
  getPurchasesTotalPaid: (state) =>
    state.purchases.reduce((acc, val) => acc + parseFloat(val.amountPaid), 0),
  getPurchasesTotalUnpaid: (state) =>
    state.purchases.reduce((acc, val) => acc + parseFloat(val.amountUnpaid), 0),
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let purchases = response.data;
      commit("SET_PURCHASES", purchases);
    });
  },

  async getAllItems({ commit }) {
    await axios.get(api + "_items").then((response) => {
      commit("setItems", response.data);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let purchase = response.data;
      commit("setPurchase", purchase);
    });
  },

  async storeItem({ commit, dispatch }, data) {
    try {
      await axios.post(api + "_items", data).then((response) => {
        let message = response.data.message;
        let status = response.data.status;

        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          commit("setItems", response.data.data);
        }
      });
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        let errorMessage = "Unprocessable Content";
        let errorMessages = [];

        if (error.response.data.errors) {
          // Construct an array of error messages from the errors object
          const errors = error.response.data.errors;
          for (const field in errors) {
            errorMessages.push(...errors[field]);
          }
          commit("SET_ERRORS", errorMessages);
          setTimeout(function () {
            commit("SET_ERRORS", []);
          }, 10000);
        }
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        console.error("API Error:", errorMessage);

        // Dispatch the error notification along with status code and error messages array
        dispatch(
          "notification/store",
          { status: "error", message: errorMessage, statusCode, errorMessages },
          { root: true }
        );
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  },

  async deleteItem({ commit, dispatch }, id) {
    await axios.delete(api + "_items/" + id).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setItems", response.data.data);
      }
    });
  },

  async store({ commit, dispatch }, data) {
    try {
      const response = await axios.post(api, data);
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status === "success") {
        commit("setPurchase", response.data.data);
        router.push({ name: "purchases-index" });
      }
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        let errorMessage = "Unprocessable Content";
        let errorMessages = [];

        if (error.response.data.errors) {
          // Construct an array of error messages from the errors object
          const errors = error.response.data.errors;
          for (const field in errors) {
            errorMessages.push(...errors[field]);
          }
          commit("SET_ERRORS", errorMessages);
          setTimeout(function () {
            commit("SET_ERRORS", []);
          }, 10000);
        }
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        console.error("API Error:", errorMessage);

        // Dispatch the error notification along with status code and error messages array
        dispatch(
          "notification/store",
          { status: "error", message: errorMessage, statusCode, errorMessages },
          { root: true }
        );
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  },
  async update({ commit }, data) {
   await axios.put(api + "/" + data.reference, data).then((response) => {
     let message = response.data.message;
     let status = response.data.status;
     let sale = response.data.data;
     dispatch("notification/store", { status, message }, { root: true });
     if (status == "success") {
       router.push({
         name: "purchases-details",
         params: {
           reference: sale.reference,
         },
       });
     }
   });
  },

  async updateItem({ commit, dispatch }, data) {
    try {
      await axios.put(api + "_items/" + data.id, data).then((response) => {
        let message = response.data.message;
        let status = response.data.status;

        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          commit("setItems", response.data.data);
        }
      });
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        let errorMessage = "Unprocessable Content";
        let errorMessages = [];

        if (error.response.data.errors) {
          // Construct an array of error messages from the errors object
          const errors = error.response.data.errors;
          for (const field in errors) {
            errorMessages.push(...errors[field]);
          }
          commit("SET_ERRORS", errorMessages);
          setTimeout(function () {
            commit("SET_ERRORS", []);
          }, 10000);
        }
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        console.error("API Error:", errorMessage);

        // Dispatch the error notification along with status code and error messages array
        dispatch(
          "notification/store",
          { status: "error", message: errorMessage, statusCode, errorMessages },
          { root: true }
        );
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  },

  async uploadSupplierOrder({ commit, dispatch }, reference) {
    await axios
      .get(api + "_upload_supplier_order/" + reference)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;

        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          router.push({ name: "purchases-index" });
        }
      });
  },

  async filter({ dispatch, commit }, data) {
    await axios.post(api + "_filter", data).then((response) => {
      let sales = response.data;
      commit("SET_PURCHASES", sales);
    });
  },
  async reportSupplier({ commit }, data) {
    await axios.post(api + "_supplier_report", data).then((response) => {
      let report = response.data;
      commit("SET_REPORT", report);
      // commit("SET_REPORT_PAYMENTS", payments);
    });
  },

  async printReportSupplier({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "Le rapport détaillé du fournisseur est en cours de téléchargement. Veuillez patienter...",
        },
        { root: true }
      );
      const response = await axios
        .post(api + "_supplier_report_print", data, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Rapport Fournisseur.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  SET_PURCHASES(state, purchases) {
    state.all = purchases;
    state.purchases = purchases;
  },
  async SET_ERRORS(state, errors) {
    state.errors = errors;
  },

  setItems(state, items) {
    state.items = items;
  },
  setPurchase(state, purchase) {
    state.purchase = purchase;
  },
  SET_REPORT(state, report) {
    state.report = report;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
